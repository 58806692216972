export const formatDate = (dateString, format) => {
    const date = new Date(dateString);
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    
    switch (format) {
        case 'mdy':
             options = { year: 'numeric', month: 'long', day: 'numeric' };
            break;
        case 'my':
            options = { year: 'numeric', month: 'long' };
            break;
        case 'y':
            options = { year: 'numeric'};
    }
    
    options = {...options, timeZone: "UTC"}
    return date.toLocaleDateString('en-US', options);
  };