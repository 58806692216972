import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Slider from "react-slick";
import eventImageFallback from "../images/events/event-4.jpg";
import { formatDate } from '../utils/format-date';
import '../stylesheets/components/reports.scss';

export default function Reports_widget(props) {

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 767, settings: { slidesToShow: 1 } },
            { breakpoint: 1024, settings: { slidesToShow: 3 } },
            { breakpoint: 100000, settings: { slidesToShow: 3 } }
        ]
    };

    return (
        <>
            <StaticQuery
                query={graphql`query FeaturedReports{
                    allNodeDarReports(limit: 3, sort: {fields: created, order: DESC}, filter:{field_featured: {eq: true}}) {
                        edges {
                            node {
                                field_link_to_full_report {
                                    uri
                                },
                                title,
                                field_date_of_report,
                                field_featured,
                                field_date_display_format,
                                relationships {
                                    field_image {
                                        localFile {
                                            childImageSharp {
                                                gatsbyImageData(width:500, height: 340)
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }    
                }
            `}
                render={data => {
                    const featuredReports = data.allNodeDarReports.edges
                    const showMoreButton = props.field_see_more_reports_button
                    const mainClass = showMoreButton ? 'blog-posts' : 'blog-posts featured-reports-non-homepage'
                    return (
                        <section className={mainClass}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h2>Featured research</h2>
                                        {featuredReports?.length === 0 ? <div>There are no featured reports at this time. Please come back later.</div> : <></>}
                                    </div>
                                </div>
                                <div className="parrent">
                                    <Slider {...settings}>
                                        {
                                            featuredReports.map((featuredReport, index) =>
                                                <div key={index} className='col-parrent'>
                                                    <div className='col'>
                                                        <div className="img-holder">
                                                            <Link to={featuredReport.node.field_link_to_full_report.uri}>
                                                                <div className="image-container">
                                                                    {
                                                                        featuredReport.node.relationships.field_image ?
                                                                            <GatsbyImage image={featuredReport.node.relationships.field_image.localFile.childImageSharp.gatsbyImageData}
                                                                                alt={featuredReport.node.relationships.field_image.alt} /> :
                                                                            <img src={eventImageFallback} alt="Report" />
                                                                    }
                                                                </div>
                                                            </Link>
                                                        </div>
                                                        <div className="details-holder">
                                                            <h4>
                                                                <Link to={featuredReport.node.field_link_to_full_report.uri}>{featuredReport.node.title}</Link>
                                                            </h4>
                                                            <small>{formatDate(featuredReport.node.field_date_of_report, featuredReport.node.field_date_display_format)}</small>
                                                        </div>
                                                    </div>
                                                </div>)
                                        }
                                    </Slider>
                                </div>
                                {showMoreButton && (
                                    <div className="row">
                                        <div className="col-sm-12 text-center">
                                            <Link className="btn btn-blue btn-lg" to="/about/reports-and-insights">See more research</Link>
                                        </div>
                                    </div>)
                                }
                            </div>
                        </section>
                    )
                }}
            />
        </>
    )
}

export const ReportsWidgetQuery = graphql`
fragment ParagraphsReportsWidget on paragraph__reports_widget {
    field_see_more_reports_button
}
`