import React, { Component } from 'react';

import Hero from '../components/c2_hero';
import HeroIllustrated from '../components/c2a_hero_illustrated';
import HeroGradient from '../components/c2b_hero_gradient';
import HeroSchool from '../components/c2d_hero_school';
import ExploreColleges from '../components/c3_explore-colleges';
import VideoAndText from '../components/c4_video-and-text';
import ThreeCardsBlueGradient from '../components/c5_three-cards-blue-gradient';
import SingleCardGradientBorder from '../components/c6_single-card-gradient-border';
import SingleCardGradientBorderB from '../components/c6b_single-card-gradient-border-b';
import BlogPostsCards from '../components/c7_blog-posts-cards';
import TwoCardsGradientBorder from '../components/c8_two_cards_gradient_border';
import EventListings from '../components/c9_event-listings';
import HowItWorks from '../components/c11_how-it-works';
import ComponentIntro from '../components/c12_component-intro';
import TextCtaImage from '../components/c13_text-cta-image';
import BluePhotoBanner from '../components/c14_blue-photo-banner';
import Breadcrumbs from '../components/c16_breadcrumbs';
import PlainAreaText from '../components/c17_plain-area-text';
import Resources from '../components/c18_resources';
import MediaWithCaption from '../components/c19_media-with-caption';
import SingleVideo from '../components/c20_video';
import QuoteStatement from '../components/c21_quote-statement';
import Accordion from '../components/c22_accordion';
import TermBox from '../components/c22b_term-box';
import TipBox from '../components/c23_tip-box';
import ApplicationSteps from '../components/c24_application-steps';
import NewsletterSignup from '../components/c26_newsletter-signup';
import People from '../components/c28_people';
import Pills from '../components/internal/pills';
import Bullets from '../components/internal/bullets';
import Gallery from "../components/internal/gallery";
import ContactForm from '../components/c29_contact_form';
import DownloadableContent from '../components/c31_downloadable_content';
import BlueBackgroundText from '../components/c32_blue-background-text';
import PurpleCards from '../components/c33_purple-cards';
import PathToCollegeCard from '../components/c34_path-to-college-card';
import YourChecklist from '../components/c35_your-checklist';

import SchoolComponent from '../components/c90_school';
import Explore from '../components/c91_explore';
import Reports from '../components/c94_reports';
import Reports_widget from '../components/c95_reports_widget';
import Blog from '../components/c92_blog';
import BlogPostHeader from '../components/c921_blog_post_header';
import Events from '../components/c93_events';
import EventHeader from '../components/c931_event_header';

import FixedContent from '../components/layouts/fixed-content';
import SidebarLayout from '../components/layouts/sidebar-layout';
import SchoolLayout from '../components/layouts/school-layout';
import VirtualTour from "../components/internal/virtual_tour";

/**
 * This component is used to render Drupal Paragraph components system
 */
export default class ComponentsManager extends Component {

    /**
     * Map Drupal Paragraph components to React components
     */
    componentsMap = {
        'paragraph__hero': Hero, // C2
        'paragraph__hero_illustrated': HeroIllustrated, // C2a
        'paragraph__hero_gradient': HeroGradient, // C2b
        'paragraph__hero_school': HeroSchool, // C2d
        'paragraph__explore_colleges': ExploreColleges, // C3
        'paragraph__video_and_text': VideoAndText, // C4
        'paragraph__three_cards_blue_gradient': ThreeCardsBlueGradient, // C5
        'paragraph__single_card_gradient_border': SingleCardGradientBorder, // C6
        'paragraph__single_card_gradient_border_b': SingleCardGradientBorderB, // C6b
        'paragraph__blog_posts_cards': BlogPostsCards, // C7
        'paragraph__two_cards_gradient_border': TwoCardsGradientBorder, // C8
        'paragraph__event_listings': EventListings, // C9
        'paragraph__how_it_works': HowItWorks, // C11
        'paragraph__component_intro': ComponentIntro, // C12
        'paragraph__text_cta_image': TextCtaImage, // C13
        'paragraph__blue_photo_banner': BluePhotoBanner, // C14
        'paragraph__breadcrumbs': Breadcrumbs, // C16
        'paragraph__plain_area_text': PlainAreaText, // C17
        'paragraph__resources': Resources, // C18
        'paragraph__media_with_caption': MediaWithCaption, // C19
        'paragraph__video': SingleVideo, // C20
        'paragraph__quote_statement': QuoteStatement, // C21
        'paragraph__accordion': Accordion, // C22
        'paragraph__term_box': TermBox, // C22b
        'paragraph__tip_box': TipBox, // C23
        'paragraph__pills': Pills, // C??
        'paragraph__bullets': Bullets, // C??
        'paragraph__gallery': Gallery, // C??
        'paragraph__virtual_tour': VirtualTour, // C??

        'paragraph__application_steps': ApplicationSteps, // C24

        'paragraph__newsletter_signup': NewsletterSignup, // C26
        'paragraph__people': People, // C28
        'paragraph__contact_form': ContactForm, // C29
        'paragraph__downloadable_content': DownloadableContent, // C31
        'paragraph__blue_background_text': BlueBackgroundText, // C32
        'paragraph__purple_cards': PurpleCards, // C33
        'paragraph__path_to_college_card': PathToCollegeCard, // C34
        'paragraph__your_checklist': YourChecklist, // C35


        'paragraph__school': SchoolComponent, // C90
        'paragraph__explore': Explore, // C91
        'paragraph__blog': Blog, // C92
        'paragraph__blog_post_header': BlogPostHeader, // C92.1
        'paragraph__events': Events, // C93
        'paragraph__event_header': EventHeader, // C93.1
        'paragraph__reports': Reports, //C94
        'paragraph__reports_widget': Reports_widget, //C95

        'paragraph__fixed_content': FixedContent,
        'paragraph__sidebar_layout': SidebarLayout,
        'paragraph__school_layout': SchoolLayout,
    };

    /**
     * Resolve components using static map
     * @param component
     * @returns {Object}
     */
    resolveComponent(component) {
        const ResolvedComponent = this.componentsMap[component.__typename];
        const key = Math.floor(Math.random() * 10000);

        if (!ResolvedComponent) {
            const errorMessage = `Component ${component.__typename} added to page, but frontend implementation is missing. Did you add it to the map? See "client/src/utils/components-manager.js"`;
            console.error(errorMessage);
            return (
                <span key={key}>{errorMessage}</span>
            );
        } else {
            return (
                <ResolvedComponent key={key} breadcrumbCategory={this.props.breadcrumbCategory} node={this.props.node} {...component} />
            );
        }
    }

    /**
     * Render components for page
     * @returns {Object}
     */
    render() {
        //console.warn("PAGE COMPONENTS", this.props.components);
        return (
            <>
                {this.props.components && this.props.components.map(component => this.resolveComponent(component))}
            </>
        )
    }
};
