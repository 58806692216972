import React, {Component} from 'react';
import { graphql } from 'gatsby';
import ComponentsManager from "../../utils/components-manager";


/**
 * This components renders hero
 */
export default class FixedContent extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props)
	{
        super(props);
        this.data = props;
    }


    /**
     * Render menu
     * @returns {*}
     */
    render()
    {

        return (
            <section className="container" role="main">
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 offset-xl-2">
                    <div className="inner-page fixed-content">
                      <ComponentsManager components={this.data.relationships.field_items} />
                    </div>
                  </div>
                </div>
            </section>
        )
    }
};

export const FixedContentQuery = graphql`
fragment ParagraphsFixedContent on paragraph__fixed_content {
  relationships {
    field_items {
      __typename
      ... on Node {
        ...ParagraphsPlainAreaText
        ...ParagraphsMediaWithCaption
        ...ParagraphsVideo
        ...ParagraphsQuoteStatement
        
        ...ParagraphsAccordion
        ...ParagraphsResources
        
        ...ParagraphsYourChecklist

        ...ParagraphHowItWorks
        ...ParagraphsVideoAndText
        ...ParagraphsThreeCardsBlueGradient
	...ParagraphsSingleCardGradientBorder
        ...ParagraphsSingleCardGradientBorderB
        ...ParagraphsTwoCardsGradientBorder
        ...ParagraphComponentIntro
        ...ParagraphTextCtaImage
        ...ParagraphsBluePhotoBanner
        ...ParagraphsTermBox
        ...ParagraphsTipBox
        ...ParagraphNewsletterSignup
        ...ParagraphsContactForm
        ...ParagraphsBlueBackgroundText
        ...ParagraphsDownloadableContent
        ...ParagraphsPurpleCards
      } 
    }
  }
}
`;
