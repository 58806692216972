import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../stylesheets/components/reports.scss';
import { formatDate } from '../utils/format-date';


export default function Reports() {

    function renderReport(reports) {
        return reports.map((report) => (
            <li className="blog-post-container" key={report.node.field_summary}>
                <div className="blog-post-details">
                    <h3 className='report-title'>
                        <Link to={report.node.field_link_to_full_report.uri}>{report.node.title} </Link>
                    </h3>
                    <div className="blog-post-publish-details report-details">
                        <span>
                            {report.node.field_report_author_source}
                        </span>
                        <span>
                            {formatDate(report.node.field_date_of_report, report.node.field_date_display_format)}
                        </span>
                    </div>
                    <p>
                        {report.node.field_summary}
                    </p>
                </div>
                <div className="blog-post-image">
                    {report.node.relationships.field_image ?
                        <GatsbyImage image={report.node.relationships.field_image.localFile.childImageSharp.gatsbyImageData}
                            className=""
                            alt={report.node.field_image.alt} /> : null
                    }
                </div>
            </li>
        ))
    }

    return (
        <>
            <StaticQuery
                query={graphql`query DarReportsAll  {
                    allNodeDarReports(sort: {fields: created, order: DESC}){
                            edges {
                                node {
                                    field_summary
                                    field_link_to_full_report {
                                        uri
                                    },
                                    title,
                                    field_date_of_report,
                                    field_report_author_source,
                                    field_date_display_format,
                                    field_report_type,
                                    field_image {
                                        alt
                                    }
                                    relationships {
                                        field_image {
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width:300 layout: FIXED)
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }`}
                render={data => {
                    let reports = data.allNodeDarReports.edges;
                    reports.sort((report1, report2) => new Date(report2.node.field_date_of_report) - new Date(report1.node.field_date_of_report))
                    let organisedReports = {
                        'Common App publications': [],
                        'Common App application trends': [],
                        'Common App research briefs': [],
                        'Third-party research and collaboration': [],
                        'Common App member case studies': [],
                    };

                    reports.forEach(report => {
                        switch (report.node.field_report_type) {
                            case 'cap':
                                organisedReports['Common App publications'].push(report);
                                break;
                            case 'at':
                                organisedReports['Common App application trends'].push(report);
                                break;
                            case 'cs':
                                organisedReports['Common App member case studies'].push(report);
                                break;
                            case 'rb':
                                organisedReports['Common App research briefs'].push(report)
                                break;
                            case 'tpc':
                                organisedReports['Third-party research and collaboration'].push(report);
                        }
                    });

                    return (
                        <>
                            <div id='report-container' className="container blogs">
                                <div className="row">
                                    <aside className={`col-lg-4`}>
                                        {/*<div>filters go here</div>*/}
                                    </aside>
                                    <main className="col-lg-8">
                                        <ul className="blog-posts-list">
                                            {/*<h2 className='category'>Common App Publications</h2>*/}
                                            {Object.keys(organisedReports).map((key) => {
                                                if (organisedReports[key].length > 0) {
                                                    return (
                                                        <>
                                                            <h2 index={key} className='category'>{key}</h2>
                                                            {renderReport(organisedReports[key])}
                                                        </>
                                                    );
                                                }
                                                return null;  // Return null for cases where the condition isn't met
                                            })}
                                        </ul>
                                    </main>
                                </div>
                            </div>
                        </>
                    )
                }
                }
            />
        </>)
}
