import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';
import ExplorePage from "./explore/explore-page";
import School from "../utils/school";

/**
 * Main Explore page component
 * This component handles data fetching for explore page.
 * It acts as a middleware between Drupal and Frontend.
 */
export default class Explore extends Component {


    constructor(props) {
        super(props);

        this.props = props;
    }

    /**
     * Fetch data and delegate page rendering to explore page wrapper
     * @returns {*}
     */
    render() {
        return (
            <StaticQuery
                query={graphql`query SchoolQueryAll {
                  allNodeSchool(filter: {path: {alias: {glob: "!/system/*"}}}) {
                    edges {
                      node {
                        title
                        path {
                          alias
                        },
                        field_filter_aanapisi
                        field_filter_aanhi
                        field_filter_hbcu
                        field_community_college
                        field_filter_hsi
                        field_filter_nasnti
                        field_filter_pbi
                        field_invert_header_colors
                        field_acc_region
                        field_acc_md_fy_participation
                        field_acc_md_tr_participation
                        relationships {
                          field_site_update {
                          
                            field_su_details_student_exp_t_o
                            field_su_details_aca_prog_tag_o
                            field_su_alternate_names
                            
                            field_su_addr_country
                            field_su_addr_street
                            field_su_addr_street_2
                            field_su_addr_city
                            field_su_addr_state
                            field_su_addr_state_full
                            field_su_addr_zip
                            field_su_addr_adm_phone
                            field_su_addr_adm_email
                            field_su_addr_adm_website
                            field_su_addr_fy_adm_website
                            field_su_addr_tr_adm_website
                            field_su_addr_fy_finaid_website
                            field_su_addr_tr_finaid_website
                            field_su_addr_ts_daca
                            field_transfer_guaranteed_admiss
                            field_su_addr_fy_daca
                            field_su_social_media_facebook
                            field_su_social_media_twitter
                            field_su_social_media_blog
                            field_su_social_media_youtube
                            field_su_social_media_instagram
                            field_su_tb_about_us
                            field_su_tb_additional_info {
                              value
                              processed
                            }
                            field_su_details_student_exp_tag
                            field_su_ff_fy_caf
                            field_su_ff_fy_per
                            field_su_ff_fy_rec_req
                            field_su_ff_fy_srs
                            field_su_ff_fy_tp
                            field_su_ff_tr_caf
                            field_su_ff_tr_per
                            field_su_ff_tr_gap
                            field_su_ff_tr_rec_req
                            field_su_ff_tr_srs
                            field_su_ff_tr_tp
                            field_su_tb_applying_to_fy
                            field_su_tb_applying_to_tr
                            field_su_tb_visiting_us
                            field_su_tb_quote_text
                            field_su_tb_quote_first_name
                            field_su_tb_quote_last_name
                            field_su_tb_quote_position
                            field_su_tb_quote_deparment
                            field_su_details_campus_setting
                            field_su_details_type
                            field_su_details_enroll_size
                            field_su_details_enroll_comp
                            field_su_lat
                            field_su_lng
                            relationships {
                              field_su_img_logo {
                                localFile {
                                  publicURL
                                }
                              }
                              field_su_gallery {
                                localFile {
                                  publicURL
                                }
                              }
                              field_su_explore_thumbnail {
                                localFile {
                                  publicURL
                                }
                              }
                            }
                          }
                          field_membership_profile {
                            field_membership_member_id
                            field_mp_membership_year
                            field_mp_fac_merit_fa
                            field_mp_fac_need_fa
                            field_mp_fac_international_fa
                          }
                          field_components {
                            __typename
                          }
                        }
                      }
                    }
                  }
                }

                `}
                render={data => (
                    <>
                        {this.renderPage(data.allNodeSchool.edges)}
                    </>
                )}
            />
        );
    }


    /**
     * Processes raw school data and parses it to school objects
     * @param data
     * @returns {*}
     */
    renderPage(data) {


        const schoolsObjects = [];
        data.forEach((item) => {
            if(item.node !== null && item.node.relationships.field_site_update !== null && item.node.relationships.field_membership_profile !== null)
            {
                schoolsObjects.push(new School(item.node));
            }
        });

        return (
            <>
                <ExplorePage schools={schoolsObjects} {...this.props} />
            </>
        )

    }
};

export const ExploreQuery = graphql`
fragment ParagraphsExplore on paragraph__explore {
  relationships {
    field_cards {
      __typename
      ... on paragraph__explore_card {
        field_title
        field_text {
          processed
        }
        links {
          alias
          title
        }
        relationships {
          field_image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
}
`;
