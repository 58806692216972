import iconRegionWest from "../images/us-map-west.svg";
import iconRegionMidAtlantic from "../images/us-map-mid-atlantic.svg";
import iconRegionMidWest from "../images/us-map-midwest.svg";
import iconRegionSouth from "../images/us-map-south.svg";
import iconRegionNewEngland from "../images/us-map-new-england.svg";
import iconRegionInternational from "../images/map-international.svg";
import iconRegionOceania from "../images/oceania.svg";
import iconRegionAlaska from "../images/us-map-alaska.svg";
import iconRegionHawaii from "../images/us-map-hawaii.svg";
import iconRegionSouthWest from "../images/us-map-southwest.svg";
import schoolFallbackImage from "../images/school-fallback-image.svg";

import iconRegionAsia from "../images/asia.svg";
import iconRegionCanada from "../images/canada.svg";
import iconRegionUK from "../images/europe-uk.svg";
import iconRegionEurope from "../images/europe-no-uk.svg";
import iconRegionSA from "../images/south-america.svg";

import usaStates from '../constants/usa-states-and-countries';

/**
 * This class holds filtering and tagging logic
 * to support explore and member profile (school) pages.
 *
 * If you wanna use field on explore, make sure you wire graphql on c91_explore.
 *
 */
export default class School {

    node = null;

    /**
     * @param data School node data from graphql
     */
    constructor(data) {
        this.node = data;
    }

    /**
     * @returns {integer}
     */
    getMemberId()
    {
        return this.node.field_member_id_apply ? this.node.field_member_id_apply : this.node.field_member_id;
    }

    /**
     * @returns {integer}
     */
    getWebAdmOrgId()
    {
        return this.node.relationships.field_site_update.field_su_webadm_id ? this.node.relationships.field_site_update.field_su_webadm_id : null;
    }

    /**
     * @returns {boolean}
     */
    isAcceptingFirstYearApplications()
    {
        // const raw = this.node.relationships.field_site_update.field_su_tb_about_us;
        const raw = this.node.field_acc_md_fy_participation;
        return raw === 'Yes' ||
            raw === 'Yes, CA Only';
    }

    /**
     * @returns {boolean}
     */
    isAcceptingTransferApplications()
    {
        const raw = this.node.field_acc_md_tr_participation;
        return raw === 'Yes' ||
            raw === 'Yes, CA Only';
    }

    isCommunityCollege()
    {
        const raw = this.node.field_community_college;
        return raw === '1' ||
            raw === 'Yes';
    }

    /**
     * Search schools by city, state, zip or country
     * @param locationOrZip
     * @returns {boolean}
     * @see explore-page.js, isPassingFilters function
     */
    hasStateOrCountry(country, state)
    {   
        let result = false;

        function locationMatch (fieldValue, searchInput) {
            let result = false
            searchInput = searchInput.trim()
            fieldValue = fieldValue.trim()
            result = fieldValue.toLowerCase().startsWith(searchInput.toLowerCase())
            return result
        }

        function locationStateMatch(fieldValue, statesArray) {
            return statesArray.some((state) => locationMatch(fieldValue, state))
        }

        if(state) {
            if(state.length === 0)
                return true;

            //this is for state abbreviations and is currently unused
            if(this.node.relationships.field_site_update.field_su_addr_state && locationStateMatch(this.node.relationships.field_site_update.field_su_addr_state, state)) {
                result = true;
            }
    
            if(this.node.relationships.field_site_update.field_su_addr_state_full && locationStateMatch(this.node.relationships.field_site_update.field_su_addr_state_full, state)) {
                result = true;
            }
            // if a US state .This is for state abbreviations and is currently unused
            if(this.node.relationships.field_site_update.field_su_addr_state &&
                this.getUsaState().toLowerCase() === state) {
                result = true;
            }
        }

        if(country) {
            if(this.node.relationships.field_site_update.field_su_addr_country && locationMatch(this.node.relationships.field_site_update.field_su_addr_country, country)) {
                result = true;
            }
        }

        if(country === 'United States of America' && state) {
            result = false;
            if(this.node.relationships.field_site_update.field_su_addr_state && locationStateMatch(this.node.relationships.field_site_update.field_su_addr_state, state)) {
                result = true;
            }
    
            if(this.node.relationships.field_site_update.field_su_addr_state_full && locationStateMatch(this.node.relationships.field_site_update.field_su_addr_state_full, state)) {
                result = true;
            }
        } else if (country && country !== 'united states of america' && state) {
            result = false
        }

        return result;
    }

    /**
     * Search schools by city, state, zip or country EXACT MATCH
     * @param locationOrZip
     * @returns {boolean}
     */
    hasLocationOrZipExactMatch(locationOrZip)
    {
        if(!locationOrZip || locationOrZip.trim() === '')
            return false;

        let result = false;

        locationOrZip = locationOrZip.toLowerCase();

        if(this.node.relationships.field_site_update.field_su_addr_city &&
            this.node.relationships.field_site_update.field_su_addr_city.toLowerCase() === locationOrZip) {
            result = true;
        }

        if(this.node.relationships.field_site_update.field_su_addr_state &&
            this.node.relationships.field_site_update.field_su_addr_state.toLowerCase() === locationOrZip) {
            result = true;
        }
        if(this.node.relationships.field_site_update.field_su_addr_state_full &&
            this.node.relationships.field_site_update.field_su_addr_state_full.toLowerCase() === locationOrZip) {
            result = true;
        }

        // if a US state
        if(this.node.relationships.field_site_update.field_su_addr_state &&
            this.getUsaState().toLowerCase() === locationOrZip) {
            result = true;
        }

        if(this.node.relationships.field_site_update.field_su_addr_zip &&
            this.node.relationships.field_site_update.field_su_addr_zip.toLowerCase() === locationOrZip) {
            result = true;
        }

        if(this.node.relationships.field_site_update.field_su_addr_country &&
            this.node.relationships.field_site_update.field_su_addr_country.toLowerCase() === locationOrZip) {
            result = true;
        }


        return result;
    }

    getUsaState = () => usaStates(this.node.relationships.field_site_update.field_su_addr_state)

    hasCityZip(cityZip)
    {
        if(!cityZip || cityZip.trim() === '')
            return false;

        if(this.node.relationships.field_site_update.field_su_addr_city &&
            this.node.relationships.field_site_update.field_su_addr_city.toLowerCase() === cityZip.toLowerCase()) {
            return true;
        }
        if(this.node.relationships.field_site_update.field_su_addr_zip &&
            this.node.relationships.field_site_update.field_su_addr_zip.toLowerCase() === cityZip.toLowerCase()) {
            return true;
        }
        return false;
    }

    /**
     * Returns all searchable tags
     *
     * @TODO Add application filters
     */
    getTags()
    {

        let tags = [];

        if(this.isAcceptingFirstYearApplications())
        {
            tags.push('Accepts first-year applications');
        }
        if(this.isAcceptingTransferApplications())
        {
            tags.push('Accepts transfer applications');
        }
        if(this.isCommunityCollege())
        {
            tags.push('Community colleges');
        }
        if(this.getRegion())
        {
            tags.push(this.getRegion());
        }
        if(this.getSchoolType())
        {
            tags.push(this.getSchoolType());
        }
        if(this.getCampusSetting())
        {
            tags.push(this.getCampusSetting());
        }
        if(this.getEnrollmentSize())
        {
            tags.push(this.getEnrollmentSize());
        }
        if(this.isWomenOnly())
        {
            tags.push('Women\'s College');
        }
        if(this.isMenOnly())
        {
            tags.push('Men\'s College');
        }
        if(this.isCoordinate())
        {
            tags.push('Coordinate');
        }
        if(this.isCoEd())
        {
            tags.push('Co-Ed');
        }
        if(this.isHbcu())
        {
            tags.push('Historically Black College and University (HBCU)');
        }
        if(this.isAanapisi())
        {
            tags.push('Asian American and Native American Pacific Islander Serving Institution (AANAPISI)');
        }
        if(this.isAanhi())
        {
            tags.push('Alaskan Native and Native Hawaiian-Serving Institution (AANHI)');
        }
        if(this.isNasnti())
        {
            tags.push('Native American Serving Nontribal Institution (NASNTI)');
        }
        if(this.isPbi())
        {
            tags.push('Predominately Black Institution (PBI)');
        }
        if(this.isHsi())
        {
            tags.push('Hispanic-Serving Institution (HSI)');
        }
        if(this.isTribal())
        {
            tags.push('Tribal Colleges & Universities');
        }
        if(this.isReligiousAffiliation())
        {
            tags.push('Religious Affiliation');
        }
        if (this.offersFinancialAidInternational()) {
            tags.push('Offers aid for international students');
        }
        if (this.offersFinancialAidMerit()) {
            tags.push('Offers merit-based aid');
        }
        if (this.offersFinancialAidNeed()) {
            tags.push('Offers need-based aid');
        }
        if (this.doesntChargeAFeeForFirstYear()) {
            tags.push('Charges no application fee - First Year');
        }
        if (this.doesntPersonalEssayForFirstYear()) {
            tags.push('No personal essay required - First Year');
        }
        if (this.doesntRecommendationForFirstYear()) {
            tags.push('No letter of recommendation required - First Year');
        }
        if (this.doesSelfReportForFirstYear()) {
            tags.push('Accepts self-reported test scores - First Year');
        }
        if (this.doesGuaranteedAPTransferStudent()) {
            tags.push('Guaranteed admission program - Transfer');
        }
        if (this.doesTestPolicyForFirstYear()) {
            tags.push('Test Optional/Flexible - First Year');
        }
        if (this.doesntChargeAFeeForTransferStudent()) {
            tags.push('Charges no application fee - Transfer');
        }
        if (this.doesntPersonalEssayForTransferStudent()) {
            tags.push('No personal essay required - Transfer');
        }
        if (this.doesntRecommendationForTransferStudent()) {
            tags.push('No letter of recommendation required - Transfer');
        }
        if (this.doesSelfReportForTransferStudent()) {
            tags.push('Accepts self-reported test scores - Transfer');
        }
        if (this.doesTestPolicyForTransferStudent()) {
            tags.push('Test Optional/Flexible - Transfer');
        }

        return tags;
    }


    getName()
    {
        return this.node.title;
    }

    getUri()
    {
        return this.node.path.alias;
    }

    getCityState = () => {
        const city = this.node.relationships.field_site_update.field_su_addr_city;
        const usaState = this.getUsaState(this.node.relationships.field_site_update.field_su_addr_state);
        const state = usaState || this.node.relationships.field_site_update.field_su_addr_country;
        return (city && state) ? `${city}, ${state}` : '';
    }

    getRegion()
    {
        return this.node.field_acc_region;
    }

    getLogoUrl()
    {
        if( !this.node.relationships.field_site_update ||
            !this.node.relationships.field_site_update.relationships.field_su_img_logo ||
            !this.node.relationships.field_site_update.relationships.field_su_img_logo.localFile)
            return null;

        return this.node.relationships.field_site_update.relationships.field_su_img_logo.localFile.publicURL;
    }

    getIpeds()
    {
        if( !this.node.relationships.field_site_update ||
            !this.node.relationships.field_site_update.field_su_ipeds)
            return null;

        return this.node.relationships.field_site_update.field_su_ipeds;
    }

    isYouVisitSuppressed () {
        if(!this.node.field_suppress_youvisit)
            return null;

        return this.node.field_suppress_youvisit;
    }


    /**
     * Checks if gallery is empty
     * @returns {boolean}
     */
    isGalleryEmpty()
    {

        if(!this.node.relationships.field_site_update.relationships.field_su_gallery ||
            this.node.relationships.field_site_update.relationships.field_su_gallery.length === 0 ||
            !Array.isArray(this.node.relationships.field_site_update.relationships.field_su_gallery)
        )
        {
            return true;
        }
    }

    /**
     * Returns array of gallery images, sorted by backend.
     * If gallery is empty, default image is added
     * @returns {Array}
     */
    getGalleryImages()
    {
        let result = [];
        if(this.isGalleryEmpty())
        {
            result.push(schoolFallbackImage);
            return result;
        }

        this.node.relationships.field_site_update.relationships.field_su_gallery.forEach((item) => {
            if(!item || !item.localFile || !item.localFile.publicURL)
                return;

            result.push(item.localFile.publicURL)
        });

        return result;
    }

    /**
     * Returns explore thumbnail or default illustration
     * @returns {publicURL|{type, args, description, resolve}}
     */
    getExploreThumbnail()
    {
        if( !this.node.relationships.field_site_update ||
            !this.node.relationships.field_site_update.relationships.field_su_explore_thumbnail ||
            !this.node.relationships.field_site_update.relationships.field_su_explore_thumbnail.localFile)
            return schoolFallbackImage;

        return this.node.relationships.field_site_update.relationships.field_su_explore_thumbnail.localFile.publicURL;
    }

    getRegionImageUrl () {

        const region = this.getRegion();

        switch (region) {
            case 'West':
                return iconRegionWest;
            case 'Southwest':
                return iconRegionSouthWest;
            case 'International':
                return iconRegionInternational;
            case 'Australia':
                return iconRegionOceania;
            case 'Hawaii':
                return iconRegionHawaii;
            case 'Mid-Atlantic':
                return iconRegionMidAtlantic;
            case 'Midwest':
                return iconRegionMidWest;
            case 'South':
                return iconRegionSouth;
            case 'New England':
                return iconRegionNewEngland;
            case 'Alaska':
                return iconRegionAlaska;
            case 'United Kingdom':
                return iconRegionUK;
            case 'Canada':
                return iconRegionCanada;
            case 'Europe':
                return iconRegionEurope;
            case 'Asia':
                return iconRegionAsia;
            case 'South America':
                return iconRegionSA;
            default:
                return null;

        }
    }

    hasCampusSetting(campusSetting)
    {

    }

    getCampusSetting()
    {
        return this.node.relationships.field_site_update.field_su_details_campus_setting;

    }

    // @TODO add application filters

    offersFinancialAidInternational()
    {
        return this.node.relationships.field_membership_profile.field_mp_fac_international_fa === 'Yes';
    }

    offersFinancialAidMerit()
    {
        return this.node.relationships.field_membership_profile.field_mp_fac_merit_fa === 'Yes';
    }

    offersFinancialAidNeed()
    {
        return this.node.relationships.field_membership_profile.field_mp_fac_need_fa === 'Yes';
    }

    doesntChargeAFeeForFirstYear () {
        return this.node.relationships.field_site_update.field_su_ff_fy_caf !== null && this.node.relationships.field_site_update.field_su_ff_fy_caf !== 'Yes';
    }

    doesntPersonalEssayForFirstYear () {
        return this.node.relationships.field_site_update.field_su_ff_fy_per !== null && this.node.relationships.field_site_update.field_su_ff_fy_per !== 'Yes';
    }

    doesntRecommendationForFirstYear () {
        return this.node.relationships.field_site_update.field_su_ff_fy_rec_req !== null && this.node.relationships.field_site_update.field_su_ff_fy_rec_req !== 'Yes';
    }

    doesSelfReportForFirstYear () {
        return this.node.relationships.field_site_update.field_su_ff_fy_srs === 'Yes';
    }

    doesTestPolicyForFirstYear () {
        return this.node.relationships.field_site_update.field_su_ff_fy_tp === 'Yes';
    }

    doesntChargeAFeeForTransferStudent () {
        return this.node.relationships.field_site_update.field_su_ff_tr_caf !== null && this.node.relationships.field_site_update.field_su_ff_tr_caf !== 'Yes';
    }

    doesntPersonalEssayForTransferStudent () {
        return this.node.relationships.field_site_update.field_su_ff_tr_per !== null && this.node.relationships.field_site_update.field_su_ff_tr_per !== 'Yes';
    }

    doesGuaranteedAPTransferStudent () {
        return this.node.relationships.field_site_update.field_su_ff_tr_gap == 'Yes' || this.node.relationships.field_site_update.field_su_ff_tr_gap === 1 || this.node.relationships.field_site_update.field_su_ff_tr_gap === '1';
    }

    doesntRecommendationForTransferStudent () {
        return this.node.relationships.field_site_update.field_su_ff_tr_rec_req !== null && this.node.relationships.field_site_update.field_su_ff_tr_rec_req !== 'Yes';
    }

    doesSelfReportForTransferStudent () {
        return this.node.relationships.field_site_update.field_su_ff_tr_srs === 'Yes';
    }

    doesTestPolicyForTransferStudent () {
        return this.node.relationships.field_site_update.field_su_ff_tr_tp === 'Yes';
    }

    isOfSchoolType(type)
    {

    }

    getSchoolType()
    {
        return this.node.relationships.field_site_update.field_su_details_type;
    }

    hasEnrollmentSize(enrollmentSize)
    {

    }

    getEnrollmentSize()
    {
        return this.node.relationships.field_site_update.field_su_details_enroll_size;
    }

    // Specialized missions


    isWomenOnly()
    {
        return this.node.relationships.field_site_update.field_su_details_enroll_comp === 'Women\'s College';
    }

    isMenOnly()
    {
        return this.node.relationships.field_site_update.field_su_details_enroll_comp === 'Men\'s College';
    }

    isCoordinate()
    {
        return this.node.relationships.field_site_update.field_su_details_enroll_comp === 'Coordinate';

    }

    isCoEd()
    {

        return this.node.relationships.field_site_update.field_su_details_enroll_comp === 'Co-Ed';
    }

    isHbcu()
    {
        return this.node.field_filter_hbcu === 'YES' || this.node.field_filter_hbcu === 1 || this.node.field_filter_hbcu === '1';

    }
    isAanapisi()
    {
        return this.node.field_filter_aanapisi === 'YES' || this.node.field_filter_aanapisi === 1 || this.node.field_filter_aanapisi === '1';
    }
    isAanhi()
    {
        return this.node.field_filter_aanhi === 'YES' || this.node.field_filter_aanhi === 1 || this.node.field_filter_aanhi === '1';
    }
    isNasnti()
    {
        return this.node.field_filter_nasnti === 'YES' || this.node.field_filter_nasnti === 1 || this.node.field_filter_nasnti === '1';
    }
    isPbi()
    {
        return this.node.field_filter_pbi === 'YES' || this.node.field_filter_pbi === 1 || this.node.field_filter_pbi === '1';
    }

    isHsi()
    {
        return this.node.field_filter_hsi === 'YES' || this.node.field_filter_hsi === 1 || this.node.field_filter_hsi === '1';
    }

    isTribal()
    {
        return this.node.field_tcu === 'YES' || this.node.field_tcu === 1 || this.node.field_tcu === '1';
    }

    isReligiousAffiliation()
    {
        return this.node.relationships.field_site_update.field_su_details_student_exp_tag.indexOf('Religious Affiliation') !== -1;
    }

    getLat()
    {
        return parseFloat(this.node.relationships.field_site_update.field_su_lat);
    }

    getLng()
    {
        return parseFloat(this.node.relationships.field_site_update.field_su_lng);
    }

    csvToArray(str, delimiter = ',',) {
        // slice from start of text to the first \n index
        // use split to create an array from string by delimiter
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

        // slice from \n index + 1 to the end of the text
        // use split to create an array of each csv value row
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");

        // Map the rows
        // split values from each row into an array
        // use headers.reduce to create an object
        // object properties derived from headers:values
        // the object passed as an element of the array
        const arr = rows.map(function (row) {
            const values = row.split(delimiter);
            const el = headers.reduce(function (object, header, index) {
                object[header] = values[index];
                return object;
            }, {});
            return el;
        });

        // return the array
        return arr;
    }

    getOtherStudentExperienceTags()
    {
        const raw = this.node.relationships.field_site_update.field_su_details_student_exp_t_o;

        let processed = [];

        if (raw) {
          //console.log(raw);
          processed = raw.split(", ")
          processed = processed.map(function (element) {
            return element.trim();
          });

        }
        return processed;
    }

    getOtherAcademicProgramTags()
    {
        // const raw = '"My school is cool, Belgrade", "Something else, Boston"';
        const raw = this.node.relationships.field_site_update.field_su_details_aca_prog_tag_o;

        let processed = [];

        if (raw) {
          //console.log(raw);
          processed = raw.split(", ")
          processed = processed.map(function (element) {
            return element.trim();
          });

        }
        return processed;
    }

    getAlternateNames()
    {
        const raw = this.node.relationships.field_site_update.field_su_alternate_names;

        return (!raw) ? '' : raw;
    }

};
